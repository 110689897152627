export function LeaveScreen({ setIsMeetingLeft }) {
  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center">
      <h1 className="text-white text-4xl mb-3">Thank You!</h1>
      <p className="text-white px-2 text-center">
        Your recording has been successfully saved.
      </p>
      <p className="text-white px-2 text-center">
        Please return to your chat to complete the process.
      </p>
      <div className="mt-12">
        <button
          className="`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm"
          onClick={() => {
            window.onbeforeunload = null;
            setIsMeetingLeft(false);
            window.location.reload(false);
          }}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
}
